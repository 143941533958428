import React from "react"
import Helmet from "react-helmet"
import Layout from "../../../components/layout"
import { Container, Row, Col } from "react-bootstrap"
import JumboPhoto from "../../../components/jumbophoto"

export default () => <Layout>
	<Helmet title="About Chapter 7 Bankruptcy | Gina Mcdonald &amp; Associates, L.L.C." defer={false} />

	<JumboPhoto/>

	<Container>
		<Row>
			<Col>
				<h1 className="text-primary">Chapter 7 Bankruptcy</h1>
				<hr />
				<p>Chapter 7 Bankruptcy is often referred to as "straight bankruptcy". Chapter 7 allows you to eliminate or discharge your unsecured debt, but keep things that are important to you, such as, your home and car. Unsecured debts include medical bills, credit card debt, pay day loans, repossession deficiencies, and personal loans.</p>
				<p>There is a misconception that you will lose everything you own when filing a Chapter 7, but this is simply not the case. You should contact an attorney near you to help you sort out your financial situation.</p>
				<p>Chapter 7 Bankruptcy will stop lawsuits, garnishments, foreclosures, and repossessions. Generally, your Chapter 7 Bankruptcy begins by meeting a qualified attorney near you, completing and filing the Bankruptcy Petition, and attending one court appearance. You will receive a discharge of your debts within about four months from your file date. Once your discharge is recorded by the Bankruptcy Court, the debts you listed such as, credit cards, medical bills, personal loans, and payday loans will be discharged. This means you will never have to repay these debts and the creditor must leave you alone and can never collect on these discharged debts.</p>

				<p className="lead">When Should I File Chapter 7 Bankruptcy?</p>
				<ul>
					<li>You cannot make ends meet.</li>
					<li>You have been served with a lawsuit.</li>
					<li>You cannot repay your credit card debt.</li>
					<li>Your wages are being garnished.</li>
					<li>You have medical bills that you can never repay.</li>
					<li>You are being called at work by creditors.</li>
					<li>You need a fresh start.</li>
					<li>You cannot provide for your family the basic necessities, such as shelter, food, clothing, and medical care due to mounting unsecured debt.</li>
					<li>You are current on home and car, but cannot pay your unsecured debt.</li>
				</ul>

				<p className="lead">Can I Keep My Home? Can I Keep My Car?</p>
				<p>In most cases, the answer is <strong>YES</strong>!</p>
				<p>We are ready to help you solve your financial crisis. Call today for a free consult <a href="tel://+12059823325">205-982-3325</a>.</p>
			</Col>
		</Row>
	</Container>
</Layout>